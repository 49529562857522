import { FormInput } from '../../components/form-input';
import { Layout } from './layout';
import { useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import yup from '../../utils/yup-utils';
import { Card, Col, FormGroup, Row } from 'react-bootstrap';
import { Link } from 'react-router-dom';
import { SubmitButton } from '../../components/submit-button';
import { API } from '../../api';
import { useState } from 'react';
import { LOGGED_IN_PATH } from '../../constants';

const schema = yup.object().shape({
    email: yup.string().required(),
    password: yup.string().required()
});

interface State {
    email: String
    password: string
}

export function LoginView() {
    const { handleSubmit, register, errors } = useForm({
        resolver: yupResolver(schema)
    });
    const [isSaving, setSaving] = useState(false);

    async function onSubmit(params: State) {
        try {
            setSaving(true);
            const response = await API.post(`/sessions`, params); console.log(response)
            localStorage.setItem(LOGGED_IN_PATH, response.data.path);
            setSaving(false);
            window.location.href = response.data.path;
        } catch (e) {
            setSaving(false);
        }
    }

    return (
        <Layout>
            <Row>
                <Col md={{ span: 6, offset: 3 }}>
                    <Card>
                        <Card.Body>
                            <h4>Login</h4>
                            <form onSubmit={handleSubmit(onSubmit)}>
                                <FormGroup>
                                    <FormInput
                                        name='email'
                                        label='Email'
                                        register={register}
                                        error={errors?.username?.message}
                                    />
                                </FormGroup>
                                <FormGroup>
                                    <FormInput
                                        name='password'
                                        label='Password'
                                        type='password'
                                        register={register}
                                        error={errors?.password?.message}
                                    />
                                </FormGroup>
                                <div>
                                    <SubmitButton
                                        block
                                        label='SIGN IN'
                                        isSaving={isSaving}
                                    />
                                </div>
                                <div className='text-right mt-2'>
                                    <Link to='/forgot-password'>Forgot Password?</Link>
                                </div>
                            </form>
                        </Card.Body>
                    </Card>
                </Col>
            </Row>
        </Layout>
    )
}