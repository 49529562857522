import { Card, Col, Form, Row } from "react-bootstrap";
import { useForm } from "react-hook-form";
import { FormInput } from "../../components/form-input";
import { FormReactSelect } from "../../components/form-react-select";
import { SubmitButton } from "../../components/submit-button";
import { Layout } from "./layout";


import { addServerErrors } from "../../utils/react-hook-form-utils";
import { ErrorAlert } from "../../components/error-alert";
import { useState } from "react";
import { API } from "../../api";
import { Dialog } from "../../components/dialog";
import { Redirect, useParams } from "react-router";
import { useQuery } from "react-query";
import { LoadingView } from "../../components/loading-view";
import { ACTIVATION } from "../../constants";
import { AxiosResponse } from "axios";

interface State {
    firstname: string
    lastname: string
    time_zone: number
    password: string
    confirm_password: string
    token: string
}

async function fetchItem(id: string) {
    const { data } = await API.get(`/activations/${id}`);
    return data;
}

export function ActivationView() {
    const { handleSubmit, register, control, errors, setError } = useForm<State>();
    const { id } = useParams<{ id: string }>();
    const { isLoading, isError, error } = useQuery([ACTIVATION, id], () => fetchItem(id));
    const [isSaving, setSaving] = useState(false);
    const [errorText, setErrorText] = useState<string>();
    const [dialog, setDialog] = useState(false);
    const [redirect, setRedirect] = useState(false);
    const timeZones: string[] = [];

    async function onSubmit(values: State) {
        try {
            setSaving(true);
            await API.put(`/activations/${id}`, values);
            setDialog(true);
            setTimeout(() => {
                setRedirect(true);
            }, 3000);
        } catch (e) {
            setSaving(false);
            if (e.data && e.data.errors) {
                addServerErrors(e.data.errors, setError);
                if (e.data.errors.base) {
                    setErrorText(e.data.errors.base);
                }
            } else {
                setErrorText(e.message);
            }
        }
    }

    if (isLoading) {
        return (
            <Layout>
                <LoadingView />
            </Layout>
        )
    }

    if (isError) {
        const message = (error as AxiosResponse).data?.message;
        return (
            <Layout>
                <ErrorAlert>{message}</ErrorAlert>
            </Layout>
        )
    }

    return (
        <Layout>
            {dialog &&
                <Dialog>
                    <p>Activation Successfull. You would be redirected to Login page.</p>
                </Dialog>
            }
            {redirect && <Redirect to='/login' />}
            <Row>
                <Col md={{ span: 6, offset: 3 }}>
                    <Card>
                        <Card.Header>
                            <Card.Title>
                                Activate Account
                            </Card.Title>
                        </Card.Header>
                        <Card.Body>
                            {errorText && <ErrorAlert>{errorText}</ErrorAlert>}
                            <form onSubmit={handleSubmit(onSubmit)}>
                                <input type='hidden' name='base' ref={register} />
                                <Form.Row>
                                    <Form.Group as={Col}>
                                        <FormInput
                                            label='Firstname'
                                            name='firstname'
                                            required
                                            register={register}
                                            error={errors?.firstname?.message}
                                        />
                                    </Form.Group>
                                </Form.Row>
                                <Form.Row>
                                    <Form.Group as={Col}>
                                        <FormInput
                                            label='Lastname'
                                            name='lastname'
                                            required
                                            register={register}
                                            error={errors?.lastname?.message}
                                        />
                                    </Form.Group>
                                </Form.Row>
                                <Form.Row>
                                    <Form.Group as={Col}>
                                        <FormReactSelect
                                            name="time_zone"
                                            label='Time Zone'
                                            options={timeZones ? timeZones : []}
                                            optionValue='id'
                                            control={control}
                                            error={errors?.time_zone?.message}
                                        />
                                    </Form.Group>
                                </Form.Row>
                                <Form.Row>
                                    <Form.Group as={Col}>
                                        <FormInput
                                            label='Password'
                                            name='password'
                                            type='password'
                                            required
                                            register={register}
                                            error={errors?.password?.message}
                                        />
                                    </Form.Group>
                                </Form.Row>
                                <Form.Row>
                                    <Form.Group as={Col}>
                                        <FormInput
                                            label='Confirm Password'
                                            name='confirm_password'
                                            type='password'
                                            required
                                            register={register}
                                            error={errors?.confirm_password?.message}
                                        />
                                    </Form.Group>
                                </Form.Row>
                                <SubmitButton block isSaving={isSaving} />
                            </form>
                        </Card.Body>
                    </Card>
                </Col>
            </Row>
        </Layout>
    )
}