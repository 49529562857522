import { Button, ButtonProps, Spinner } from 'react-bootstrap';

export interface Props extends ButtonProps {
    label?: string
    isSaving?: boolean
    disabled?: boolean
}

export function SubmitButton(props: Props) {
    const { label, isSaving, disabled, size, ...rest } = props;

    return (
        <Button type='submit' variant='success' className='text-uppercase' size={size} disabled={isSaving || disabled} {...rest}>
            { isSaving && <Spinner animation='border' size='sm' className='mr-2' />}
            { label ? label : 'Submit'}
        </Button>
    )
}