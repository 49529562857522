import { Route, Redirect } from "react-router";

const loggedIn = true;

export const PrivateRoute = (props: any) => {
    const { component, ...rest } = props;
    const Component = component;

    return (
        <Route
            {...rest}
            render={props =>
                loggedIn ? (
                    <Component {...props} />
                ) : (
                    <Redirect
                        to={{
                            pathname: "/",
                            state: { from: props.location }
                        }}
                    />
                )
            }
        />
    )
};