import axios from 'axios';

export const API_ENDPOINT = process.env.REACT_APP_API_ENDPOINT;

const API = axios.create({
    baseURL: API_ENDPOINT,
    headers: {
        'Accept': 'application/json',
        'Content-Type': 'application/json'
    }
});

API.defaults.xsrfCookieName = "CSRF-TOKEN";
API.defaults.xsrfHeaderName = "X-CSRF-Token";
API.defaults.withCredentials = true;

API.interceptors.response.use((response) => {
    return response;
}, function (error) {
    if (error && error.response.status === 401) {
        localStorage.removeItem('isLoggedIn');
        window.location.href = "/login";
    }
    return Promise.reject(error.response);
});

export {
    API
}