export const ASSESSMENTS = 'ASSESSMENTS';
export const ACTIVATION = 'ACTIVATION';
export const ADMIN_ORGANIZATIONS = 'ADMIN_ORGANIZATIONS';
export const ADMIN_ORG = 'ADMIN_ORG';
export const ADMIN_ORG_USERS = 'ADMIN_ORG_USERS';
export const ADMIN_ORG_ASSESSMENTS = 'ADMIN_ORG_ASSESSMENTS';
export const ASSESSMENT_SHOW = 'ASSESSMENT_SHOW';

export const CURRENT_ORG = 'CURRENT_ORG';
export const CONDITION_CLINICAL_STATUSES = 'CONDITION_CLINICAL_STATUSES';

export const DOCUMENTS = 'DOCUMENTS';

export const ETHNICITIES = 'ETHNICITIES';

export const GENDER_LIST = 'GENDER_LIST';

export const LOGGED_IN_PATH = 'LOGGED_IN_PATH';
export const LANGUAGES = 'LANGUAGES';

export const MEMBERSHIP_LIST = 'MEMBERSHIP_LIST';
export const METRIC_CHART = 'METRIC_CHART';
export const MARTIAL_STATUS_LIST = 'MARTIAL_STATUS_LIST';

export const ORG_ASSESSMENTS = 'ORG_ASSESSMENTS';
export const ORG_ASSESSMENT = 'ORG_ASSESSMENT';
export const ORG_BOARD_AVAIL_PROG = 'ORG_BOARD_AVAIL_PROG';
export const ORG_BOARDS = 'ORG_BOARDS';
export const ORG_BOARD = 'ORG_BOARD';
export const ORG_MEMBERSHIPS = 'ORG_MEMBERSHIPS';
export const ORG_PROGRAMS = 'ORG_PROGRAMS';
export const ORG_PROGRAM = 'ORG_PROGRAM';
export const ORG_USERS = 'ORG_USERS';
export const ORG_ORGS = 'ORG_ORGS';
export const ORG_ORG = 'ORG_ORG';
export const OUTCOME_STATUSES = 'OUTCOME_STATUSES';

export const PROFILE = 'PROFILE';
export const PROGRAMS = 'PROGRAMS';
export const PATIENTS = 'PATIENTS';
export const PATIENT = 'PATIENT';
export const PATIENT_ALLERGIES = 'PATIENT_ALLERGIES';
export const PATIENT_ALLERGY = 'PATIENT_ALLERGY';
export const PATIENT_ASSESSMENTS = 'PATIENT_ASSESSMENTS';
export const PATIENT_ASSESSMENT = 'PATIENT_ASSESSMENT';
export const PATIENT_ASSESSMENT_SELECTION = 'PATIENT_ASSESSMENT_SELECTION';
export const PATIENT_BOARD = 'PATIENT_BOARD';
export const PATIENT_CURRENT_ENCOUNTER = 'PATIENT_CURRENT_ENCOUNTER';
export const PATIENT_CAREPLANS = 'PATIENT_CAREPLANS';
export const PATIENT_CAREPLAN = 'PATIENT_CAREPLAN';
export const PATIENT_COVERAGES = 'PATIENT_COVERAGES';
export const PATIENT_COVERAGE = 'PATIENT_COVERAGE';
export const PATIENT_GOAL = 'PATIENT_GOAL';
export const PATIENT_HEALTH_PROVIDERS = 'PATIENT_HEALTH_PROVIDERS';
export const PATIENT_HEALTH_PROVIDER = 'PATIENT_HEALTH_PROVIDER';
export const PATIENT_INSURANCE = 'PATIENT_INSURANCE';
export const PATIENT_IMMUNIZATIONS = 'PATIENT_IMMUNIZATIONS';
export const PATIENT_IMMUNIZATION = 'PATIENT_IMMUNIZATION';
export const PATIENT_LAB_ORDERS = 'PATIENT_LAB_ORDERS';
export const PATIENT_LAB_ORDER = 'PATIENT_LAB_ORDER';
export const PATIENT_MEDICATIONS = 'PATIENT_MEDICATIONS';
export const PATIENT_MEDICATION = 'PATIENT_MEDICATION';
export const PATIENT_NOTES = 'PATIENT_NOTES';
export const PATIENT_REFERRAL_INS = 'PATIENT_REFERRAL_INS';
export const PATIENT_REFERRAL_OUTS = 'PATIENT_REFERRAL_OUTS';
export const PATIENT_VITALS = 'PATIENT_VITALS';

export const PATIENT_NEW_GOAL = 'PATIENT_NEW_GOAL';
export const PATIENT_EDIT_GOAL = 'PATIENT_EDIT_GOAL';
export const PROGRAM_BOARD = 'PROGRAM_BOARD';

export const RACES = 'RACES';
export const RESOURCE_CATEGORIES = 'RESOURCE_CATEGORIES';

export const SMOKING_STATUS_LIST = 'SMOKING_STATUS_LIST';

export const TRANSPORTATIONS = 'TRANSPORTATIONS';
export const TIME_ZONES = 'TIME_ZONES';

export const US_STATES = 'US_STATES';