import React from 'react';
import { FormLabel } from 'react-bootstrap';
import { ErrorText } from './error-text';
import { Required } from './required';

type RefReturn =
    | string
    | ((instance: HTMLInputElement | null) => void)
    | React.RefObject<HTMLInputElement>
    | null
    | undefined;

export type InputProps = React.DetailedHTMLProps<
    React.InputHTMLAttributes<HTMLInputElement>,
    HTMLInputElement
> & {
    label?: string
    register?: ({ required }: { required?: boolean }) => RefReturn
    error?: string
}


export function FormInput(props: InputProps) {
    const { label, register, required, error, ...rest } = props;

    return (
        <>
            <FormLabel className='label-2 text-uppercase'>
                {label} {required && <Required />}
            </FormLabel>
            <input
                type="text"
                ref={register && register({ required })}
                className="form-control"
                required={required}
                {...rest}
                aria-invalid={error ? 'true' : 'false'}
            />
            { error && <ErrorText>{error}</ErrorText>}
        </>
    )
}