import { Modal } from "react-bootstrap";

export interface Props {
    title?: string
    children?: React.ReactChild
}

export function Dialog(props: Props) {
    return (
        <Modal show={true}>
            {
                props.title &&
                <Modal.Header>
                    <Modal.Title>{props.title}</Modal.Title>
                </Modal.Header>
            }
            {
                props.children &&
                <Modal.Body>
                    {props.children}
                </Modal.Body>
            }
        </Modal>
    )
}