import { useEffect, useState } from 'react';
import { Redirect } from 'react-router';
import { LOGGED_IN_PATH } from '../../constants';

export function RootView() {
    const [redirect, setRedirect] = useState<string>();

    useEffect(() => {
        const loggedInPath = localStorage.getItem(LOGGED_IN_PATH); console.log(loggedInPath)
        if (loggedInPath) {
            setRedirect(loggedInPath);
        } else {
            setRedirect("/login");
        }
    }, []);

    return (
        <>
            {redirect && <Redirect to={redirect} />}
        </>
    )
}