import * as yup from 'yup';

yup.setLocale({
    mixed: {
        required: () => {
            return 'is required'
        }
    }
});

export default yup;