import { Required } from './required';
import { FormLabel } from 'react-bootstrap';
import { Controller } from 'react-hook-form';
import { ErrorText } from './error-text';
import Select from 'react-select';

export type Props<T> = {
    name: string
    label: string
    control: any
    options: T[]
    optionValue?: string
    optionLabel?: string
    required?: boolean
    placeholder?: string
    disabled?: boolean
    isMulti?: boolean
    isClearable?: boolean
    value?: T
    //register?: ({ required }: { required?: boolean }) => RefReturn
    error?: string
}

export function FormReactSelect<T>(props: Props<T>) {
    const { label, control, name, required, error, placeholder, disabled, options,
        isMulti, isClearable } = props;
    const { optionLabel, optionValue } = props;
    const ol = optionLabel ? optionLabel : 'label';
    const ov = optionValue ? optionValue : 'value';

    let value = undefined;

    if (props.isMulti) {
        const values: any = props.value;
        value = props.value ? props.options.filter((x: any) => values.indexOf(x[ov]) !== -1) : [];
    } else {
        value = props.options.find((x: any) => props.value && x[ov] === props.value);
    }

    return (
        <>
            <FormLabel className='label-2 text-uppercase'>
                {label} {required && <Required />}
            </FormLabel>
            <Controller
                name={name}
                control={control}
                defaultValue={value}
                render={(props) => (
                    <Select
                        placeholder={placeholder}
                        isDisabled={disabled}
                        getOptionLabel={(x) => x[ol]}
                        getOptionValue={(x) => x[ov]}
                        options={options}
                        onBlur={props.onBlur}
                        isMulti={isMulti}
                        isClearable={isClearable}
                        value={props.value}
                        onChange={(v) => {
                            if (isMulti) {
                                const values = v ? v.map((x: any) => x[ov]) : [];
                                props.onChange(values);
                            } else {
                                props.onChange(v ? v[ov] : undefined)
                            }
                        }}
                    />
                )}
            />
            {error && <ErrorText>{error}</ErrorText>}
        </>
    )
}