import React, { useEffect, useState } from 'react';
import { LoadingView } from './components/loading-view';
import { BrowserRouter, Route, Switch } from 'react-router-dom';
import { LoginView } from './views/site/login-view';
import { RootView } from './views/site';
import { API } from './api';
import { PrivateRoute } from './components/private-route';
import { QueryClient, QueryClientProvider } from 'react-query';
import { ActivationView } from './views/site/activation-view';

const queryClient = new QueryClient({
    defaultOptions: {
        queries: {
            refetchOnWindowFocus: false
        }
    }
});

const AppRoot = React.lazy(() => import('./views/app/index'));
const AdminRoot = React.lazy(() => import('./views/admin/index'));

function App() {
    const [isFetching, setFetching] = useState(false);

    useEffect(() => {
        fetchSession();
    }, []);

    async function fetchSession() {
        try {
            setFetching(true);
            await API.get('/sessions/new');
            setFetching(false);
        } catch (e) {
            setFetching(false);
        }
    }

    if (isFetching) {
        return <LoadingView />
    }

    return (
        <QueryClientProvider client={queryClient}>
            <React.Suspense fallback={<LoadingView />}>
                <BrowserRouter>
                    <Switch>
                        <Route path="/" exact component={RootView} />
                        <Route path="/login" component={LoginView} />
                        <Route path="/activations/:id" component={ActivationView} />
                        <PrivateRoute path="/app" component={(props: any) => <AppRoot {...props} />} />
                        <PrivateRoute path="/admin" component={(props: any) => <AdminRoot {...props} />} />
                    </Switch>
                </BrowserRouter>
            </React.Suspense>
        </QueryClientProvider>
    );
}

export default App;
