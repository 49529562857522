import React from 'react';
import logo from '../../assets/images/logo.png';

export interface Props {
    children: React.ReactNode
}

export function Layout({ children }: Props) {
    return (
        <div className='container'>
            <div className='row'>
                <div className='col-sm-12 col-md-8 offset-md-2'>
                    <div className='text-center m-4'>
                        <a href='/' title='habitnu'>
                            <img alt='logo' src={logo} title='Habitnu' />
                        </a>
                        <div style={{ fontSize: 24, textAlign: 'center', letterSpacing: 8 }}>
                            CAREPLAN
                        </div>
                    </div>
                </div>
            </div>
            {children}
        </div>
    )
}